import React from 'react'
import PropTypes from 'prop-types'
import {Col, Container, Row, Spinner, Table} from 'reactstrap'
import {fetchRatings} from '../api'
import {css} from '@emotion/react'
import StarRatingComponent from 'react-star-rating-component'
import Layout from './Layout'

// Rating response
// 
// "id": 7,
// "username": "Julie",
// "item_title": "L'Enfant sauvage",
// "headline": "Very good",
// "content": "Très bon film",
// "rating": 5,
// "pub_date": "2019-05-21",
// "object_id": 3,
// "user": 9,
// "content_type": 5

const RatingRow = props => (
  <div className="bg-white w-100 col-12 rounded shadow-sm border border-white pt-3 px-3 my-2" >
    <span className="font-weight-bold"> {props.rating.item_title} </span>
    <br />
    <StarRatingComponent 
      name="rating" 
      editing={false}
      starCount={5}
      value={props.rating.rating}
      className="position-absolute"
    /> <p className="font-weight-lighter text-small mb-1" style={{marginLeft: 90}}>({props.rating.pub_date})</p>

    <p className="font-weight-bold mb-1">{props.rating.headline} </p>
    <p className="font-weight-light mb-4">{props.rating.content} </p>
  </div>
)


const getRatingRows = ratings => {
  if (!ratings.length) return
  let counter = 0
  const ratingRows = ratings.map(rating => {
    counter++
    console.log(rating)
    return <RatingRow rating={rating}/>
  })
  return ratingRows
}

export default class UserRatingsList extends React.Component{
  static propTypes = {
    user: PropTypes.object.isRequired, 
    userId: PropTypes.number,
    ratings: PropTypes.array,
  }

  state = {
    username: '',
    ratings: null,
    isSelf: false,
  }

  async componentDidMount(){
    if (this.props.userId === this.props.user.username && !this.props.ratings) {
      this.setState({isSelf: true, username: this.props.user.username})
      this.getRatings()
    }
  }

  getRatings = async () => {
    const response = await fetchRatings(this.props.token)
    this.setState({ratings: response})
  }

  render(){
    if (!this.props.ratings && !this.state.ratings) return <div css={container} className="d-flex"><Spinner className="m-auto" size="sm" /></div>

    let rows = null
    try {
      rows = getRatingRows(this.props.ratings)
    } catch(err) {
      rows = getRatingRows(this.state.ratings)
    }

    if (!rows) return <p className="text-center text-muted mx-auto my-4"> Vous n'avez pas encore noté de films.</p>

    return (
      // <Layout>
        <Container className="px-2" style={{ maxWidth: 600 }}>
          {rows}
        </Container>
      //</Layout> 
    )
  }
}

const container = css({
  minHeight: '60vh',
}) 