import React from 'react'
import {Col, Container, Row, Spinner, Table, TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText,} from 'reactstrap'
import {fetchRatings} from '../api'
import {css} from '@emotion/react'
import UserRatingList from './UserRatingsList'
import Layout from './Layout'
import UserRecommendations from './UserRecommenations'
import { Trans } from 'react-i18next'

const getRatingRows = ratings => {
  if (!ratings.length) return
  let counter = 0
  const ratingRows = ratings.map(i => {
    counter++
    return (
      <tr>
        <th scope="row">{counter}</th>
        <td>{i.item_title}</td>
        <td>{i.pub_date}</td>
        <td>{i.rating}</td>
      </tr>
    )
  })
  return ratingRows
}

class Profile extends React.Component{
  state = {
    username: '',
    ratings: null,
    isSelf: false,
    activeTab: '1',
  }

  async componentDidMount(){
    if (this.props.userId === this.props.user.username) {
      this.setState({isSelf: true, username: this.props.user.username})
      const response = await fetchRatings(this.props.token)
      console.log(response)
      this.setState({ratings: response})
    }
  }

  toggle = tab => {
    if(this.state.activeTab !== tab) this.setState({activeTab: tab});
  }

  render(){
    if (!this.state.ratings) return  <Layout><div css={container} className="d-flex"><Spinner className="m-auto" size="sm" /></div></Layout>

    // const rows = getRatingRows(this.state.ratings)
    return (
      <Layout>
        <Container>
          <Row>
            <Col>
              <div css={container}>
                <h1 className="my-5 font-weight-bold">{this.state.username}</h1>
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={this.state.activeTab === '1' && 'active bg-transparent btn' }
                        onClick={() => { this.toggle('1'); }}
                      >
                        <Trans>Mes notes</Trans>
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        className={this.state.activeTab === '2' && 'active bg-transparent btn'}
                        onClick={() => { this.toggle('2'); }}
                      >
                        Recommandé pour vous
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <div>
                            <h4 className="my-3 text-center display"><Trans>Mes notes</Trans></h4>
                            <UserRatingList user={this.props.user} ratings={this.state.ratings}/>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    {/* <TabPane tabId="2">
                      <Row>
                        <Col sm="12" md={{size: 8, offset: 2}}>
                          <h4 className="my-3 text-center display">Recommandations</h4>
                          <UserRecommendations justTheList/>
                        </Col>
                      </Row>
                    </TabPane> */}
                  </TabContent>
                </div>
                <div>

                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

const container = css({
  minHeight: '60vh',
}) 

export default Profile