import React from "react"
import ModalAuth from './ModalAuth'
import LayoutMinimal from './LayoutMinimal'
import {Button, Container, Row} from 'reactstrap'

import logo from '../images/logo.png'
import { parseUrlParams } from "../utils"
import { logoutRequest } from "../api"
import { Trans } from "react-i18next"

class Login extends React.Component {
  async componentDidMount(){
    const params = parseUrlParams(this.props.location.search); 
    if (params.logout) {
      logoutRequest()
      localStorage.clear()
    }
  }
  render() {
    return (
      <LayoutMinimal>
        <Container style={{minHeight: '70vh'}}>
          <Row>
            <img className="text-center mx-auto mt-5 rounded" width="80" height="80" src={logo}/>
          </Row>
          <Row>
            <h5 className="font-weight-bold text-center mx-auto mt-5 mb-4"><Trans>Identifiez-vous</Trans></h5>
          </Row>
          <Row>
            <div className="mx-auto">
              <div className="d-flex">
                <ModalAuth>
                  <Button color="danger" className="font-weight-bold mx-1"><Trans>Se connecter</Trans></Button>
                </ModalAuth>
                <ModalAuth auth="signup">
                  <Button color="danger" className="font-weight-bold mx-1"><Trans>Créer un compte</Trans></Button>
                </ModalAuth>
              </div>
            </div>
          </Row>
        </Container>
      </LayoutMinimal>
    )
  }
}

export default Login