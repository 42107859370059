import React from 'react'
import {Button, Col, Container, Row, Spinner} from 'reactstrap'
import {navigate} from 'gatsby'
import {confirmEmail} from '../api'
import {css} from '@emotion/react'
import LayoutMinimal from './LayoutMinimal'

class ConfirmEmail extends React.Component{
  state = {
    message: null,
    details: null,
  }

  async componentDidMount(){
    try {
      const {message, details}  = await confirmEmail({key: this.props.confirmationKey})
      this.setState({ message: message, details: details })
    } catch (err) {
      const {message, details} = JSON.parse(err.message)
      console.log(err)
      this.setState({ message: message, details: details })
    }
  }

  render(){
    if (!this.state.message) return <div className="d-flex" css={container}><Spinner className="m-auto" color="primary" /></div>
    return (
      <LayoutMinimal>
        <Container>
          <Row>
            <Col>
              <div className="my-4" css={container}>
                <h2 className="helsinki">{this.state.message}</h2>
                <p>{this.state.details}</p>
                <Button className="helsinki" color="success" onClick={() => navigate('/')}>Terminé</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </LayoutMinimal>
    )
  }
}

const container = css({
  minHeight: '60vh',
}) 

export default ConfirmEmail