import React from "react"
import { Router } from "@reach/router"
import {connect} from 'react-redux'
import Profile from "../components/Profile"
import UserRatingsList from "../components/UserRatingsList"
import UserRecommenations from "../components/UserRecommenations"
import ConfirmEmail from "../components/ConfirmEmail"
import Login from "../components/Login"
import PasswordReset from "../components/PasswordReset"
import { graphql } from "gatsby"

// import Layout from "../components/Layout"
// import UserSettings from "../components/UserSettings"

const App = ({user, token}) => (
  // <Layout>
    <Router>
      <Login path="/user/login" />
      <Login path="/:lang/user/login" />
      <ConfirmEmail path="/user/confirm_email/:confirmationKey"/>
      <ConfirmEmail path="/:lang/user/confirm_email/:confirmationKey"/>
      <PasswordReset path="/user/password_reset/"/>
      <PasswordReset path="/:lang/user/password_reset/"/>
      <Profile path="/user/:userId" user={user} token={token}/>
      <Profile path="/:lang/user/:userId" user={user} token={token}/>
      <UserRatingsList path="/user/:userId/ratings" user={user} token={token} />
      <UserRatingsList path="/:lang/user/:userId/ratings" user={user} token={token} />
      <UserRecommenations path="/user/:userId/recommendations" user={user} token={token} />
      <UserRecommenations path="/:lang/user/:userId/recommendations" user={user} token={token} />
      {/* <UserSettings path="/user/:userId" user={user} token={token}/> */}
    </Router>
  // </Layout>
)

const mapStateToProps = state => ({
  user: state.user.user,
  token: state.user.token,
})

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default connect(mapStateToProps)(App)