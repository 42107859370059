import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { css } from '@emotion/react'
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';


import { getMovieRecommendations } from '../redux/actions'

import { Container, Spinner } from 'reactstrap'

import Layout from './Layout'
import MovieCardLargeList from './MovieCardLargeList'

class UserRecommendations extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    token: PropTypes.string.isRequired,
    userId: PropTypes.string,
    ratings: PropTypes.array,
    recommendedMovies: PropTypes.object,
    getMovieRecommendations: PropTypes.func.isRequired,
  }

  state = {
    friend: null,
    loading: false,
  }

  async componentDidMount() {
    const url = `${process.env.GATSBY_API_LIVE_URL}/fr/user/recommendations`
    this.props.getMovieRecommendations(url)
  }

  changeViewers = e => {
    const friend = e.target.value
    console.log(friend, this.state.friend)
    if (friend === this.state.friend) return
    if (friend === 'alone' && this.state.friend === null) return

    let url = ''
    if (friend === 'alone'){
      this.setState({friend: null})
      url = `${process.env.GATSBY_API_LIVE_URL}/fr/user/recommendations`
    } else {
      this.setState({friend})
      url = `${process.env.GATSBY_API_LIVE_URL}/fr/user/recommendations?friend=${friend}`
    }
    this.props.getMovieRecommendations(url)
  }


  render() {
    const { recommendedMovies } = this.props.recommendedMovies
    const { loading } = this.state

    let body = ''

    if (!recommendedMovies || loading) {body = <div css={container} className="d-flex"><Spinner className="m-auto" size="sm" /></div>}
    else if (recommendedMovies.length === 0) {body = <p className="text-center text-muted mx-auto my-4"> Recommendations en cours de génération. <br /> (N'oubliez pas de noter au moins 10 films)</p>}
    else {
      body = (
      <>
        {
          !this.props.justTheList &&
          <>
            <UncontrolledButtonDropdown>
              <DropdownToggle tag="a" className="mb-2" caret>
              Avec {this.state.friend}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header >Ajouter des amis</DropdownItem>
                <DropdownItem value="alone" onClick={this.changeViewers}>Personne</DropdownItem>
                <DropdownItem divider />
                <DropdownItem value="mike2" onClick={this.changeViewers} >Mike</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
            <UncontrolledButtonDropdown>
              <DropdownToggle tag="a" className="mb-2 ml-3" caret>
              Sur <span className="text-danger">Netflix</span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header >Plateformes de VOD</DropdownItem>
                <DropdownItem value="Netflix" className="text-danger">Netflix</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </>
        }
        <MovieCardLargeList movies={recommendedMovies}/>
      </>
      )
    }

    return (
      <>
        {
          !this.props.justTheList &&
          <Layout>
            <Container>
              <h4 className="font-weight-bold mt-2">Regarder</h4>
              { body }
            </Container>
          </Layout>
        }
        { body }
      </>
    )
  }
}

const mapStateToProps = state => ({
  recommendedMovies: state.recommendedMovies,
})

export default connect(mapStateToProps, {getMovieRecommendations})(UserRecommendations)

const container = css({
  minHeight: '60vh',
}) 