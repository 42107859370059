import React from "react"
import LayoutMinimal from './LayoutMinimal'
import {Alert, Button, Col, Container, Row, Input, Label, Spinner} from 'reactstrap'

import logo from '../images/logo.png'
import { parseUrlParams } from "../utils"
import { sendEmailWithResetPasswordLink, verifyResetPasswordToken, changeUserPassword } from '../api'
import { navigate } from "gatsby"
import { toast } from "react-toastify"

const SuccessMessage = props => (
  <div>
    <Alert color="success">
      <h4 className="alert-heading">Succès</h4>
      <p>
        { props.text1 ||
          "Un email avec un lien de réinitialisation du mot de passe à été envoyé à l'adresse saisie."
        }
      </p>
      <hr />
      <p className="mb-0">
        { props.text2 || 
          "Cliquez sur le lien présent dans l'email pour réinitialiser votre mot de passe."
        }
      </p>
    </Alert>
  </div>
)
const ErrorMessage = props => (
  <div>
    <Alert color="danger">
      <h4 className="alert-heading">Erreur</h4>
      <p> 
        { props.text1 || 
          "L'adresse email indiqué n'existe pas." 
        }
      </p>
      <hr />
      <p className="mb-0"> 
        { props.text2 || 
          "Essayez-en une autre ou créez un compte"
        }
      </p>
    </Alert>
  </div>
)

class PasswordReset extends React.Component {

  state = {
    success: false,
    error: false,
    successPasswordChanged: false,
    errorPasswordChanged: false,
    email: null,
    token: null,
    password1: null,
    password2: null,
    loading: false,
  }

  componentDidMount(){
    const params = parseUrlParams(this.props.location.search); 
    if (params.token) {
      const token = params.token
      this.setState({ token, success: false, error: false })
    }
  }

  submitEmail = async () => {
    const { email } = this.state
    if (this.state.loading === true) return
    try {
      this.setState({ loading:true })
      await sendEmailWithResetPasswordLink(email)
      this.setState({success: true, error: false, loading:false})
    } catch (err) {
      toast.error(err.message, {position: 'top-right'})
      this.setState({success: false, error: true, loading:false})
    }
  }

  submitNewPassword = async () => {
    const { password1, token } = this.state
    try {
      const response = await changeUserPassword(password1, token)
      if (response !== 'error') {
        this.setState({successPasswordChanged: true, errorPasswordChanged: false})
      }
    } catch (err) {
      toast.error(err.message, {position: 'top-right'})
      this.setState({successPasswordChanged: false, errorPasswordChanged: true})
    }
  }

  handleInputChange = event => {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		this.setState({[name]: value})
  }
  
  render() {

    const { token, success, error, password1, password2, successPasswordChanged, errorPasswordChanged } = this.state

    let submitDisabled = false
    let message = ''
    
    if (password1 !== password2 && password1 !== null) {
      message = "Les mots de passes ne correspondent pas"
      submitDisabled = true
    } else if (password1 === null || password1.length < 4) {
      message = "Mot de passe trop court"
      submitDisabled = true
    }


    const passChangeForm = (
      <div className="m-2">
        <Label for="password1">Nouveau mot de passe</Label>
        <Input
          type="password"
          name="password1"
          id="password1"
          value={this.state.password1}
          onChange={this.handleInputChange}
          placeholder="Nouveau mot de passe"
          className="mr-2 mb-4 w-100"
        />
        <Label for="password1">Confirmez nouveau mot de passe</Label>
        <Input
          type="password"
          name="password2"
          id="password2"
          value={this.state.password2}
          onChange={this.handleInputChange}
          placeholder="Confirmez nouveau mot de passe"
          className="mr-2 mb-4 w-100"
        />
        <p className="text-muted">{ message }</p>
        <Button disabled={submitDisabled} color="success" onClick={this.submitNewPassword}>Envoyer</Button>
        {/* <p className="text-muted">{ message }</p> */}
      </div>
    )

    const sendEmailForm = (
      <>
        <Label for="email">Email</Label>
        <div className="d-flex">
          <Input
            type="email"
            name="email"
            id="email"
            value={this.state.email}
            onChange={this.handleInputChange}
            placeholder="Adresse email"
            className="mr-2"
          />
          <Button color="success" onClick={this.submitEmail}>{ this.state.loading &&<Spinner size="sm"/> || 'Envoyer' } </Button>
        </div>
      </>
    )

    return (
      <LayoutMinimal>
        <Container style={{minHeight: '70vh'}}>
          <Row>
            <Col sm="12" md={{ size: 4, offset: 4 }}>
              <Row>
                <img className="text-center mx-auto mt-5 rounded" width="80" height="80" src={logo}/>
              </Row>
              <Row>
                <h5 className="font-weight-bold text-center mx-auto mt-5 mb-4">Récupération du compte</h5>
              </Row>
              <Row>
                <div className="w-100">
                  { success && <SuccessMessage/> }
                  { error &&  <ErrorMessage/> }
                  { successPasswordChanged && <SuccessMessage 
                    text1="Mot de passe modifié avec succès" 
                    text2="Vous pouvez à présent vous connecter à l'aide de votre nouveau mot de passe." /> }
                  { errorPasswordChanged &&  <ErrorMessage 
                    text1="Impossible de modifier le mot de passe" 
                    text2="Il est possible que le lien ait expiré ou que vous ayez déja changé votre mote de passe. Cela peut aussi arriver si vous avez mal copié le lien reçu par email. Réssayer l'opération depuis le début. Si le problème persiste, contactez-nous. contact@cinetimes.org"/> }
                  { token ?
                    !successPasswordChanged && passChangeForm :
                    !success && sendEmailForm
                  }
                </div>
              </Row>
            
            </Col>
          </Row>
        </Container>
      </LayoutMinimal>
    )
  }
}

export default PasswordReset